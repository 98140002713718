import * as React from "react"
import PropTypes from "prop-types"

import HeaderPage from "../header/header-page"
import Footer from "../footer/footer"

const LayoutPage = ({ isContactPage, children }) => (
  <>
    {isContactPage
      ?
      <HeaderPage isContactPage />
      :
      <HeaderPage />
    }
    <div>
      <main>
        {children}
      </main>
    </div>
    <Footer />
  </>
)

LayoutPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutPage