import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow-blue {
    text-shadow: 0 3px 3px #003057;
  }
`

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  background: #A6192E;
`

const PageTermsSection = () => (
  <>
    <div className="relative" style={{ display: "grid", background: "#423E42" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 300,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Terms Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={1920}
        className="hidden md:block"
      />

      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 200,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Terms Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={800}
        className="block md:hidden"
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
          <StyledDiv className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
            <h2 className='text-4xl lg:text-5xl font-medium text-white text-center text-shadow-blue uppercase mt-6'>
              <span className='leading-tight'>Terms of Service</span>
            </h2>
          </StyledDiv>
        </div>
      </div>
    </div>

    <div className="w-full bg-gray-100">
      <div className='relative w-11/12 max-w-screen-xl bg-gray-100 py-14 md:py-20 lg:py-24 lg:w-10/12 mx-auto'>
        <div className='w-full md:w-9/12 mx-auto'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Tiarna Real Estate Services Terms of Service</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>
        </div>

        <div className='w-full md:w-9/12 mx-auto'>

          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>1. Terms</h3>
          <p className='text-xl text-gray-900 mb-6'>By accessing the website at www.tiarna.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>2. Use License</h3>
          <ol className='list-disc text-xl text-gray-900 mb-6 ml-8'>
            <li>Permission is granted to temporarily download one copy of the materials (information or software) on Tiarna's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
              <ol className='list-decimal text-xl text-gray-900 mb-6 mt-6 ml-12'>
                  <li className='mb-4'>modify or copy the materials;</li>
                  <li className='mb-4'>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                  <li className='mb-4'>attempt to decompile or reverse engineer any software contained on Tiarna's website;</li>
                  <li className='mb-4'>remove any copyright or other proprietary notations from the materials; or</li>
                  <li className='mb-4'>transfer the materials to another person or "mirror" the materials on any other server.</li>
              </ol>
            </li>
            <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Tiarna at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
          </ol>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>3. Disclaimer</h3>
          <ol className='list-disc text-xl text-gray-900 mb-6 ml-8'>
            <li className='mb-4'>The materials on Tiarna's website are provided on an 'as is' basis. Tiarna makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
            <li>Further, Tiarna does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
          </ol>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>4. Limitations</h3>
          <p className='text-xl text-gray-900 mb-6'>In no event shall Tiarna or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Tiarna's website, even if Tiarna or a Tiarna authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>5. Accuracy of materials</h3>
          <p className='text-xl text-gray-900 mb-6'>The materials appearing on Tiarna's website could include technical, typographical, or photographic errors. Tiarna does not warrant that any of the materials on its website are accurate, complete or current. Tiarna may make changes to the materials contained on its website at any time without notice. However Tiarna does not make any commitment to update the materials.</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>6. Links</h3>
          <p className='text-xl text-gray-900 mb-6'>Tiarna has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Tiarna of the site. Use of any such linked website is at the user's own risk.</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>7. Modifications</h3>
          <p className='text-xl text-gray-900 mb-6'>Tiarna may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>8. Governing Law</h3>
          <p className='text-xl text-gray-900 mb-6'>These terms and conditions are governed by and construed in accordance with the laws of California and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
        </div>
      </div>
    </div>
  </>
)

export default PageTermsSection