const menuItemsContactPage = [
  {
    path: "/#home",
    label: "Home",
  },
  {
    path: "/#advantage",
    label: "Advantage",
  },
  {
    path: "/#services",
    label: "Services",
  },
  {
    path: "/#financial-services",
    label: "Financial Services",
  },
]

export default menuItemsContactPage