import React from "react"

import LayoutPage from "../components/layout/layout-page"
import PageTermsSection from "../components/sections/page-terms-section"
import Seo from "../components/seo"

const TermsPage = () => (
  <LayoutPage>
    <Seo title="Terms of Service" />

    <PageTermsSection />
  </LayoutPage>
)

export default TermsPage
