import React, { useState, useEffect } from "react"
import { navigate } from '@reach/router'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import styled from "styled-components"
import { MenuIcon } from '@heroicons/react/outline'
import { LocationMarkerIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid"

import menuItemsPage from "./header-page.data"
import menuItemsContactPage from "./header-contact-page.data"
import LinkedinIcon from '../../assets/linkedin.svg'
import FacebookIcon from '../../assets/facebook.svg'
import HeaderLogo from "./header-logo"
import HeaderButton from "./header-button"

const MobileDrawerPage = loadable(() =>
  pMinDelay(import('./mobile-drawer-page'), 2000),{
    fallback:
      <div className="w-2/3 xl:hidden flex justify-end">
        <button
          className="text-site-gray focus:outline-none focus:ring-2 focus:ring-white bg-transparent hover:bg-transparent p-0"
        >
          <span className="sr-only">Open panel</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
  }
)

const StyledNav = styled.nav`
  a.active span {
    color: #A6192E;
  }
`

const HeaderPage = ({ isContactPage }) => {
  const [scrolled, setScrolled] = useState(false)

  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 50 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  return (
    <>
      <div className="bg-site-blue w-full">
        <div className={`w-full max-w-screen-xl flex flex-col md:flex-row items-center justify-between mx-auto py-3 px-6 xl:px-14`}>
        <div className="flex flex-wrap items-center justify-center mb-2 md:mb-0">
            <div className="flex items-center mr-6 mb-2 md:mb-0">
              <LocationMarkerIcon className="h-4 w-4 text-site-red" aria-hidden="true" />
              <a
                href='https://goo.gl/maps/15cHJZxWZbMQyP5c6'
                target='_blank'
                rel='noopener noreferrer'
                className="text-white text-sm hover:underline ml-1"
              >
                2603 Main Street
              </a>
            </div>
            <div className="flex items-center md:mr-6 mb-2 md:mb-0">
              <MailIcon className="h-4 w-4 text-site-red" aria-hidden="true" />
              <a
                href='mailto:info@tiarna.com'
                target='_blank'
                rel='noopener noreferrer'
                className="text-white text-sm hover:underline ml-1"
              >
                info@tiarna.com
              </a>
            </div>
            <div className="flex items-center">
              <PhoneIcon className="h-4 w-4 text-site-red" aria-hidden="true" />
              <a
                href='tel:949-833-8334'
                target='_blank'
                rel='noopener noreferrer'
                className="text-white text-sm hover:underline ml-1"
              >
                949-833-8334
              </a>
            </div>
          </div>

          <div className="flex items-center">
            <p className="text-white text-sm mr-4">Follow Us:</p>
            <div className='w-4 mr-4'><a href='https://www.linkedin.com/company/tiarna-real-estate-services-inc./' target='_blank' rel='noopener noreferrer'><LinkedinIcon /><span className='hidden'>LinkedIn</span></a></div>

            <div className='w-4'><a href='https://www.facebook.com/pages/Tiarna-Real-Estate-Services/163031227049640' target='_blank' rel='noopener noreferrer'><FacebookIcon /><span className='hidden'>Facebook</span></a></div>
          </div>
        </div>
      </div>
      <div className={`${scrolled ? 'sticky top-0 bg-white border-b' : 'bg-transparent'} w-full z-40`}>
        <header className={`w-full max-w-screen-xl flex items-center justify-between mx-auto py-4 px-6 xl:px-14`}>
          <HeaderLogo />

          <StyledNav className="hidden xl:flex items-center">
            {isContactPage
              ?
              menuItemsContactPage.map(({ path, label }, i) => (
                <button
                  onClick={event => {
                    event.preventDefault()
                    navigate(path)
                  }}
                  key={i}
                >
                  <span className="text-base text-site-blue font-bold uppercase mx-6 cursor-pointer hover:text-site-red">{label}</span>
                </button>
              ))
              :
              menuItemsPage.map(({ path, label }, i) => (
                <button
                  onClick={event => {
                    event.preventDefault()
                    navigate(path)
                  }}
                  key={i}
                >
                  <span className="text-base text-site-blue font-bold uppercase mx-6 cursor-pointer hover:text-site-red">{label}</span>
                </button>
              ))
            }

            {isContactPage
              ?
              null
              :
              <HeaderButton />
            }
          </StyledNav>

          {isContactPage
            ?
            <MobileDrawerPage isContactPage />
            :
            <MobileDrawerPage />
          }
        </header>
      </div>
    </>
  )
}

export default HeaderPage
